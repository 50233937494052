<template>
  <AdminHeader v-if="hasRole('Admin')" />
  <SuperUserHeader v-else-if="hasRole('SuperUser')" />
  <UserHeader v-else />

  <div class="w-100">
    <div class="mx-2">
      <div class="p-4 rounded">
        <h2 class="mb-2">{{ this.workout.name }}</h2>
        <p class="mb-2">{{ this.workout.description }}</p>
        <div class="row">
          <div v-for="workoutSet in this.workout.workoutSets" :key="workoutSet.id" class="col-md-6 mb-4">
            <div class="card mb-2">
              <div class="card-body">
                <h5 class="card-title"><span class="badge bg-primary mr-2">{{ workoutSet.order }}</span> {{ workoutSet.exercise.name }}</h5>
                <p class="card-text"><strong>Beschrijving:</strong> {{ workoutSet.exercise.description }}</p>
                <div class="row">
                  <div class="col-md-6">
                    <p><strong>Type:</strong> {{ workoutSet.exercise.type }}</p>
                  </div>
                  <div class="col-md-6">
                    <p><strong>Spiergroep:</strong> {{ workoutSet.exercise.bodyPart }}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <p><strong>Aantal sets:</strong> {{ workoutSet.sets }}</p>
                  </div>
                  <div class="col-md-6">
                    <p><strong>Aantal reps:</strong> {{ workoutSet.reps }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
</template>

<script>
import { getWorkout } from "@/services/api";
import SuperUserHeader from "@/components/headers/SuperUserHeader";
import AdminHeader from "@/components/headers/AdminHeader";
import UserHeader from "@/components/headers/UserHeader";
import { mapGetters } from "vuex";

export default {
  components: { SuperUserHeader, AdminHeader, UserHeader, },
  computed: {
    ...mapGetters(['roles']),
  },
  props: ['id'],
  name: "WorkoutDetailsView",
  data() {
    return {
      workoutId: null,
      workout: {
        name: "",
        description: "",
        workoutSets: [],
      },
    };
  },
  created() {
    this.workoutId = this.$route.params.id;
    this.fetchWorkoutDetails();
  },
  methods: {
    fetchWorkoutDetails() {
      getWorkout(this.workoutId)
          .then(response => {
            this.workout = response.data;
            this.workoutSets = this.workout.workoutSets;
          })
          .catch(error => {
            console.error('Fout bij ophalen workoutgegevens:', error);
          });
    },
    hasRole(role) {
      return this.roles.includes(role);
    },
  }
}
</script>

<style scoped>

</style>