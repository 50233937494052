<template>
  <nav class="navbar navbar-expand-lg bg-dark px-2">
    <a class="navbar-brand text-white" href="#" @click="navigateToMyPlanning">WorkoutPlanner</a>
    <button class="navbar-toggler" type="button" :data-toggle="'collapse'" :data-target="'#navbarNav'" :aria-controls="'navbarNav'" :aria-expanded="false" :aria-label="'Toggle navigation'" @click="toggleNavbar">
      <i class="fas fa-bars" style="color:#fff; font-size:28px;"></i>
    </button>
    <div class="navbar-collapse collapse" :class="{ 'show': isNavbarOpen }" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link text-white" href="#" @click="navigateToMyPlanning">Mijn Planning</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white" href="#" @click="navigateToMyStatistics">Mijn Statistieken</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white" href="#" @click="navigateToClients">Cliënten</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white" href="#" @click="navigateToWorkouts">Workouts</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white" href="#" @click="navigateToExercises">Oefeningen</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white" href="#" @click="handleLogout">Logout</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      isNavbarOpen: false
    };
  },
  methods: {
    ...mapActions(['logout']),
    navigateToMyPlanning() {
      this.$router.push('/my-workout-plan');
    },
    navigateToMyStatistics() {
      this.$router.push('/my-statistics');
    },
    navigateToWorkouts() {
      this.$router.push('/workouts');
    },
    navigateToExercises() {
      this.$router.push('/exercises');
    },
    navigateToClients() {
      this.$router.push('/clients');
    },
    handleLogout() {
      this.logout();
      this.$router.push('/login');
    },
    toggleNavbar() {
      console.log('x');
      this.isNavbarOpen = !this.isNavbarOpen;
    }
  }
};
</script>