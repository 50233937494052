<template>
  <div>
    <AdminHeader v-if="hasRole('Admin')" />
    <SuperUserHeader v-else-if="hasRole('SuperUser')" />
    <UserHeader v-else />
    <div class="w-100">
      <responsive-page-wrapper>
        <CanvasJSChart :options="options" :style="styleOptions" @chart-ref="chartInstance"/>
        <div v-if="bmiData.length === 0 && muscleMassData.length === 0 && fatMassData.length === 0">
          <p style="color: red"><b>Je hebt nog geen statistieken ingevoerd! Voeg hieronder je statistieken toe</b></p>
        </div>
        <router-link :to="{ name: 'AddStatistics' }" class="btn btn-primary">
          Statistiek toevoegen
        </router-link>
        <hr>
        <p><b>BMI</b> is het gewicht van iemand (in kilogram) gedeeld door het kwadraat van zijn lengte (in meters) geeft de body mass index in kg/m2. De BMI is ingedeeld in de categorieën ondergewicht, gezond gewicht en overgewicht. Overgewicht is onderverdeeld in matig en ernstig overgewicht (obesitas).</p>
        <p><b>Spiermassa</b> is het gewicht van de spieren in uw lichaam. Er bestaat geen ideale spiermassa. Wel moet het gehalte spiermassa ten opzichte van vocht en vetmassa goed zijn. Over het algemeen bestaat er een sterk verschil in spiermassa tussen mannen en vrouwen.</p>
        <p><b>Vetmassa</b>  is dat deel van het totale lichaamsgewicht dat bestaat uit vetweefsel. Vetvrije massa is het deel van het totale lichaamsgewicht dat bestaat uit spierweefsel, botten en vocht. Vetweefsel wordt ook wel passiefweefsel genoemd. Het gebruikt geen energie in rustmomenten.</p>
        <hr>
      </responsive-page-wrapper>
    </div>
  </div>
</template>

<script>
import SuperUserHeader from "@/components/headers/SuperUserHeader";
import AdminHeader from "@/components/headers/AdminHeader";
import UserHeader from "@/components/headers/UserHeader";
import ResponsivePageWrapper from '@/components/ResponsivePageWrapper';
import { getStatistics } from "@/services/api";
import {mapGetters} from "vuex";

export default {
  components: { SuperUserHeader, AdminHeader, UserHeader, ResponsivePageWrapper},
  computed: {
    ...mapGetters(['roles']),
  },
  data() {
    return {
      chart: null,
      chartLabels: [],
      chartData: [],
      bmiData: [],
      muscleMassData: [],
      fatMassData: [],
      statistics: [],
      options: {
        theme: "light2",
        exportEnabled: true,
        title: {
          text: "Mijn statistieken"
        },
        axisX: {
          title: "Tijdsverloop"
        },
        axisY: {
          title: "Waarde"
        },
        toolTip: {
          shared: true
        },
        legend: {
          cursor: "pointer"
        },
        data: [
          {
            type: "line",
            name: "BMI (Body Mass Index)",
            showInLegend: true,
            color: "#ffdd33",
            toolTipContent: "<strong>BMI</strong>: {y}",
            dataPoints: [],
          },
          {
            type: "line",
            name: "Spiermassa (in kilogram)",
            showInLegend: true,
            color: "#ff3355",
            toolTipContent: "<strong>Spiermassa</strong>: {y} kg",
            dataPoints: [],
          },
          {
            type: "line",
            name: "Vetmassa (in kilogram)",
            showInLegend: true,
            color: "#1368ce",
            toolTipContent: "<strong>Vetmassa</strong>: {y} kg",
            dataPoints: [],
          },
        ],
    },
      styleOptions: {
        width: "100%",
        height: "480px"
      },
    };
  },
  mounted() {
    this.loadStatistics();
  },
  methods: {
    async loadStatistics() {
      const fetchedStatistics = await this.fetchStatistics();
      this.addStatisticsToChart(fetchedStatistics);
    },
    async fetchStatistics() {
      try {
        const response = await getStatistics();
        this.statistics = response.data;
        return this.statistics;
      } catch (error) {
        console.error('Er is iets fout gegaan tijdens het ophalen van de statistieken:', error);
      }
    },
    addStatisticsToChart(fetchedStatistics) {
      if (fetchedStatistics && fetchedStatistics.length > 0) {
        const bmiData = [];
        const muscleMassData = [];
        const fatMassData = [];
        const labels = [];

        fetchedStatistics.forEach(s => {
          labels.push(s.createdAt.split("T")[0]);
          bmiData.push(s.bmi);
          muscleMassData.push(s.muscleMass);
          fatMassData.push(s.fatMass);
        });

        this.chartLabels = labels;
        this.bmiData = bmiData;
        this.muscleMassData = muscleMassData;
        this.fatMassData = fatMassData;

        this.options.data[0].dataPoints = this.chartLabels.map((label, index) => ({
          label: label,
          y: this.bmiData[index],
        }));

        this.options.data[1].dataPoints = this.chartLabels.map((label, index) => ({
          label: label,
          y: this.muscleMassData[index],
        }));

        this.options.data[2].dataPoints = this.chartLabels.map((label, index) => ({
          label: label,
          y: this.fatMassData[index],
        }));

        this.chart.render();
      }
    },
    chartInstance(chart) {
      this.chart = chart;
    },
    hasRole(role) {
      return this.roles.includes(role);
    },
  }
};
</script>
