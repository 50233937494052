<template>
  <super-user-header class="header"></super-user-header>
  <div class="w-100">
    <responsive-form-wrapper @submit="submitForm">
        <h2>Workout Plan Toevoegen</h2>

        <div class="form-group mb-3">
          <label>Selecteer een Workout:</label>
          <select v-model="selectedWorkout" class="form-control" required>
            <option v-for="workout in workouts" :key="workout.id" :value="workout.id">{{ workout.name }}</option>
          </select>
        </div>
        <div class="form-group mb-3">
          <label>Selecteer een Datum:</label>
          <input type="date" v-model="selectedDate" class="form-control" required>
        </div>
        <div class="form-group mb-3">
          <label>Beschrijving / Notities:</label>
          <textarea v-model="description" class="form-control" rows="5"></textarea>
        </div>
        <button type="submit" :disabled="onSubmitting" class="btn btn-primary w-100">Toevoegen</button>
    </responsive-form-wrapper>
  </div>
</template>

<script>
import SuperUserHeader from "@/components/headers/SuperUserHeader";
import { addWorkoutPlansForUser, getAllWorkouts } from "@/services/api";
import ResponsiveFormWrapper from '@/components/ResponsiveFormWrapper.vue';

export default {
  components: { SuperUserHeader, ResponsiveFormWrapper },
  props: ['id'],
  name: "AddWorkoutPlanView",
  data() {
    return {
      onSubmitting: false,
      userId: null,
      selectedWorkout: null,
      selectedDate: '',
      description: '',
      workouts: [],
    };
  },
  created() {
    this.userId = this.$route.params.id;
    this.fetchWorkouts();
  },
  methods: {
    async fetchWorkouts() {
      try {
        const response = await getAllWorkouts();
        this.workouts = response.data;
      } catch (error) {
        console.error('Error fetching workouts:', error);
      }
    },
    submitForm() {
      if (!this.onSubmitting) {
        this.onSubmitting = true;
        try {
          const addWorkoutPlan = {
            workoutId: this.selectedWorkout,
            date: this.selectedDate,
            description: this.description
          };

          addWorkoutPlansForUser(this.userId, addWorkoutPlan)
              .then(() => {
                this.$router.push({name: 'WorkoutPlans'});
              }).finally(() => {
                this.onSubmitting = false;
          });
        } catch (error) {
          console.error('Fout bij het toevoegen van workout plan:', error);
          this.onSubmitting = false;
        }
      }
    }
  }
}
</script>
