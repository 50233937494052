<template>
  <div class="w-100">
    <responsive-form-wrapper @submit="handleLogin">
      <div class="container text-center mt-2">
        <div class="row">
          <div class="col">
            <img src="@/assets/logo.png" style="max-width: 150px;" class="img-fluid mx-auto d-block" alt="Logo">
          </div>
        </div>
      </div>
      <h2 class="mt-3">WorkoutPlanner - Login</h2>
      <div class="mb-3">
          <label for="email" class="form-label">Email:</label>
          <input type="text" v-model="email" id="email" class="form-control" placeholder="Email address" required />
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password:</label>
          <input type="password" v-model="password" id="password" class="form-control" placeholder="Password" required />
        </div>
        <p v-if="errorMessage" style="color: red;">{{ errorMessage }}</p>
        <button type="submit" :disabled="onSubmitting" class="btn btn-primary w-100">Login</button>
    </responsive-form-wrapper>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ResponsiveFormWrapper from '@/components/ResponsiveFormWrapper.vue';
import { useStore } from 'vuex';
export default {
  components: {
    ResponsiveFormWrapper,
  },
  data() {
    return {
      onSubmitting: false,
      email: 'user4@example.com',
      password: '!String123',
      errorMessage: '',
    };
  },
  mounted() {
    this.storedCredentialsLogin();
  },
  methods: {
    ...mapActions(['login']),
    async handleLogin() {
      try {
        if (!this.onSubmitting) {
          this.onSubmitting = true;
          await this.login({email: this.email, password: this.password});

          const roles = this.$store.getters.roles;
          if (roles.includes('Admin')) {
            this.$router.push('/my-workout-plan');
          } else if (roles.includes('SuperUser')) {
            this.$router.push('/my-workout-plan');
          } else if (roles.includes('User')) {
            this.$router.push('/my-workout-plan');
          } else {
            console.warn('User has no valid roles:', roles);
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.errorMessage = 'Onjuiste email-wachtwoord combinatie. Probeer opnieuw.';
        } else {
          this.errorMessage = 'Er is iets fout gegaan tijdens het inloggen. Probeer het later opnieuw.';
        }
      } finally {
        this.onSubmitting = false
      }
    },
    async storedCredentialsLogin() {
      try {
        const store = useStore();
        await store.dispatch('loginWithStoredCredentials', this.$router);
      } catch (error) {
        console.warn('Auto login failed:', error);
      }
    },
  }
};
</script>