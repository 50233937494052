<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light mx-2">
    <a class="navbar-brand" href="#" @click="navigateToMyPlanning">WorkoutPlanner</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" href="#" @click="navigateToMyPlanning">Mijn Planning</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click="navigateToMyStatistics">Mijn Statistieken</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click="handleLogout">Logout</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['logout']),
    navigateToMyPlanning() {
      this.$router.push('/my-workout-plan');
    },
    navigateToMyStatistics() {
      this.$router.push('/my-statistics');
    },
    handleLogout() {
      this.logout();
      this.$router.push('/login');
    }
  }
};
</script>