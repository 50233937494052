<template>
    <super-user-header class="header"></super-user-header>
    <div class="w-100">
      <responsive-page-wrapper>
      <h2>Tabel met geregistreerde oefeningen</h2>

      <div class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th>Naam</th>
            <th>Omschrijving</th>
            <th>Type</th>
            <th>Lichaamsdeel</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="exercise in exercises" :key="exercise.id">
            <td>{{ exercise.name }}</td>
            <td>{{ exercise.description }}</td>
            <td>{{ exercise.type }}</td>
            <td>{{ exercise.bodyPart }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <button @click="navigateToAddExercise" class="btn btn-primary">Oefening toevoegen</button>
  </responsive-page-wrapper>
  </div>
</template>

<script>
import SuperUserHeader from "@/components/headers/SuperUserHeader";
import { getAllExercises } from '@/services/api';
import ResponsivePageWrapper from '@/components/ResponsivePageWrapper';

export default {
  components: { SuperUserHeader, ResponsivePageWrapper },
  data() {
    return {
      exercises: []
    };
  },
  mounted() {
    this.fetchExercises();
  },
  methods: {
    navigateToAddExercise() {
      this.$router.push('/add-exercise');
    },
    async fetchExercises() {
      try {
        const response = await getAllExercises();
        this.exercises = response.data;
      } catch (error) {
        console.error('Error fetching exercises:', error);
      }
    }
  }
};
</script>
