import { createRouter, createWebHistory } from 'vue-router';
import LoginForm from '../components/LoginForm.vue';
import store from '../store';
import UserView from "@/views/UserView";
import AdminView from "@/views/AdminView";
import SuperUserView from "@/views/SuperUserView";
import AddExerciseView from "@/views/AddExerciseView";
import ExercisesView from "@/views/ExercisesView";
import WorkoutsView from "@/views/WorkoutsView";
import AddWorkoutView from "@/views/AddWorkoutView";
import EditWorkoutView from "@/views/EditWorkoutView";
import ClientsView from "@/views/ClientsView";
import WorkoutPlans from "@/views/WorkoutPlansView";
import AddWorkoutPlan from "@/views/AddWorkoutPlanView";
import WorkoutDetails from "@/views/WorkoutDetailsView";
import MyWorkoutPlans from "@/views/MyWorkoutPlansView";
import MyStatistics from "@/views/MyStatisticsView";
import AddStatistics from "@/views/AddStatisticsView";

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginForm,
        meta: { requiresAuth: false },
    },
    {
        path: '/user',
        name: 'User',
        component: UserView,
        meta: { roles: ['User'], requiresAuth: true }
    },
    {
        path: '/admin',
        name: 'Admin',
        component: AdminView,
        meta: { roles: ['Admin'], requiresAuth: true }
    },
    {
        path: '/superuser',
        name: 'SuperUser',
        component: SuperUserView,
        meta: { roles: ['SuperUser'], requiresAuth: true }
    },
    {
        path: '/add-exercise',
        name: 'AddExercise',
        component: AddExerciseView,
        meta: { roles: ['SuperUser'], requiresAuth: true }
    },
    {
        path: '/workouts',
        name: 'Workouts',
        component: WorkoutsView,
        meta: { roles: ['SuperUser'], requiresAuth: true }
    },
    {
        path: '/add-workout',
        name: 'AddWorkouts',
        component: AddWorkoutView,
        meta: { roles: ['SuperUser'], requiresAuth: true }
    },
    {
        path: '/edit-workout/:id',
        name: 'EditWorkout',
        component: EditWorkoutView,
        meta: { roles: ['SuperUser'], requiresAuth: true }
    },
    {
        path: '/exercises',
        name: 'Exercises',
        component: ExercisesView,
        meta: { roles: ['SuperUser'], requiresAuth: true }
    },
    {
        path: '/clients',
        name: 'Clients',
        component: ClientsView,
        meta: { roles: ['SuperUser'], requiresAuth: true }
    },
    {
        path: '/clients/:id/workout-plans',
        name: 'WorkoutPlans',
        component: WorkoutPlans,
        meta: { roles: ['SuperUser'], requiresAuth: true }
    },
    {
        path: '/clients/:id/add-workout-plan',
        name: 'AddWorkoutPlan',
        component: AddWorkoutPlan,
        meta: { roles: ['SuperUser'], requiresAuth: true }
    },
    {
        path: '/workout/:id',
        name: 'WorkoutDetails',
        component: WorkoutDetails,
        meta: { roles: ['SuperUser', 'User', 'Admin'], requiresAuth: true }
    },
    {
        path: '/my-workout-plan',
        name: 'MyWorkoutPlans',
        component: MyWorkoutPlans,
        meta: { roles: ['SuperUser', 'User', 'Admin'], requiresAuth: true }
    },
    {
        path: '/my-statistics',
        name: 'MyStatistics',
        component: MyStatistics,
        meta: { roles: ['SuperUser', 'User', 'Admin'], requiresAuth: true }
    },
    {
        path: '/add-statistics',
        name: 'AddStatistics',
        component: AddStatistics,
        meta: { roles: ['SuperUser', 'User', 'Admin'], requiresAuth: true }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.roles)) {
        if (!store.getters.isAuthenticated) {
            next({ name: 'Login' });
        } else {
            const requiredRoles = to.meta.roles;
            const userRoles = store.getters.roles;
            if (requiredRoles.some(role => userRoles.includes(role))) {
                next();
            } else {
                next({ name: 'Login' });
            }
        }
    } else {
        next();
    }
});

export default router;