import axios from 'axios';
import store from '@/store';

const createApiClient = () => {
    const token = store.state.token;

    return axios.create({
        baseURL: 'https://localhost:7202/api',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token ? `Bearer ${token}` : '',
        }
    });
};

export const addWorkout = (workoutData) => {
    const apiClient = createApiClient();
    return apiClient.post('/workouts', workoutData);
};

export const getWorkout = (id) => {
    const apiClient = createApiClient();
    return apiClient.get(`/workouts/${id}`);
};

export const getAllWorkouts = () => {
    const apiClient = createApiClient();
    return apiClient.get('/workouts');
};

export const editWorkout = (workoutId, workout, workoutSets) => {
    const apiClient = createApiClient();
    return apiClient.put(`/workouts/${workoutId}`, { workout: workout, workoutSets: workoutSets });
};

export const addExercise = (exerciseData) => {
    const apiClient = createApiClient();
    return apiClient.post('/exercises', exerciseData);
};

export const getAllExercises = () => {
    const apiClient = createApiClient();
    return apiClient.get('/exercises');
};

export const getAllUsers = () => {
    const apiClient = createApiClient();
    return apiClient.get('/user');
};

export const getWorkoutPlansFromUser = (userId = null) => {
    const apiClient = createApiClient();
    const endpoint = userId ? `/WorkoutPlans/${userId}` : `/WorkoutPlans/${store.state.uuid}`;
    return apiClient.get(endpoint);
};

export const addWorkoutPlansForUser = (userId, newWorkoutPlan) => {
    const apiClient = createApiClient();
    return apiClient.post(`/WorkoutPlans/${userId}`, newWorkoutPlan);
};

export const addStatistics = (newStatistics) => {
    const apiClient = createApiClient();
    return apiClient.post(`/statistics/${store.state.uuid}`, newStatistics);
};

export const getStatistics = () => {
    const apiClient = createApiClient();
    return apiClient.get(`/statistics/${store.state.uuid}`);
};