<template>
  <div class="w-100">
    <super-user-header class="header"></super-user-header>
      <responsive-form-wrapper @submit="submitForm">
        <h2>Statistiek toevoegen</h2>

        <div class="mb-3">
          <label for="bmi" class="form-label">BMI:</label>
          <input type="number" id="bmi" class="form-control" min="1" max="100" v-model="statistics.bmi" required>
        </div>

        <div class="mb-3">
          <label for="muscleMass" class="form-label">Spiermassa (in kilogram):</label>
          <input type="number" id="muscleMass" class="form-control" min="1" max="100" v-model="statistics.muscleMass" required>
        </div>

        <div class="mb-3">
          <label for="fatMass" class="form-label">Vetmassa (in kilogram):</label>
          <input type="number" id="fatMass" class="form-control" min="1" max="100" v-model="statistics.fatMass" required>
        </div>

        <button type="submit" :disabled="onSubmitting" class="btn btn-primary w-100">Toevoegen</button>
      </responsive-form-wrapper>
  </div>
</template>

<script>
import SuperUserHeader from "@/components/headers/SuperUserHeader";
import ResponsiveFormWrapper from '@/components/ResponsiveFormWrapper.vue';
import { addStatistics } from '@/services/api';

export default {
  components: { SuperUserHeader, ResponsiveFormWrapper },
  data() {
    return {
      onSubmitting: false,
      statistics: {
        bmi: '',
        muscleMass: '',
        fatMass: '',
        createdAt: '',
        updatedAt: '',
      }
    };
  },
  mounted() {
  },
  methods: {
    submitForm() {
      if (!this.onSubmitting) {
        this.onSubmitting = true;
        addStatistics(this.statistics)
          .then(response => {
            console.log('Workout toegevoegd:', response.data);
            this.$router.push('/my-statistics');
          })
          .catch(error => {
            console.error('Er is iets fout gegaan tijdens het toevoegen van de statistieken:', error);
          })
          .finally(() => {
            this.onSubmitting = false;
          })
      }
    },
  }
};
</script>
