<template>
  <div>
    <super-user-header class="header"></super-user-header>
    <h1>SuperUser View</h1>
    <p>Welcome, {{ email }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SuperUserHeader from "@/components/headers/SuperUserHeader";

export default {
  components: {SuperUserHeader},
  computed: {
    ...mapGetters(['email']),
  },
  methods: {
  },
};
</script>