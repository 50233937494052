<template>
  <div>
    <super-user-header class="header"></super-user-header>
    <div class="w-100">
      <responsive-page-wrapper>
        <h2>Gebruikers</h2>

        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th>Email</th>
              <th>Rol</th>
              <th>Workout Plans</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.email }}</td>
              <td>{{ user.role }}</td>
              <td>
                <router-link :to="{ name: 'WorkoutPlans', params: { id: user.id } }" class="btn btn-primary">
                  Bekijk Workout Plans
                </router-link>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </responsive-page-wrapper>
    </div>
  </div>
</template>

<script>
import SuperUserHeader from "@/components/headers/SuperUserHeader";
import { getAllUsers } from '@/services/api';
import ResponsivePageWrapper from '@/components/ResponsivePageWrapper';

export default {
  components: { SuperUserHeader, ResponsivePageWrapper },
  data() {
    return {
      users: []
    };
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await getAllUsers();
        this.users = response.data;
      } catch (error) {
        console.error('Error fetching workouts:', error);
      }
    }
  }
};
</script>
