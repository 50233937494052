import { createStore } from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate'

axios.defaults.baseURL = 'https://localhost:7202/api';

export default createStore({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        uuid: null,
        token: null,
        expiration: null,
        roles: [],
        email: null
    },
    mutations: {
        setUuid(state, uuid) {
            state.uuid = uuid;
        },
        setToken(state, token) {
            state.token = token;
        },
        setExpiration(state, expiration) {
            state.expiration = expiration;
        },
        setRoles(state, roles) {
            state.roles = roles;
        },
        setEmail(state, email) {
            state.email = email;
        },
        clearAuthData(state) {
            state.uuid = null;
            state.token = null;
            state.expiration = null;
            state.roles = [];
            state.email = null;
        }
    },
    actions: {
        async login({ commit }, { email, password }) {
            try {
                const response = await axios.post('/Login', {
                    email,
                    password,
                });
                commit('setUuid', response.data.uuid);
                commit('setToken', response.data.token);
                commit('setExpiration', response.data.expiration);
                commit('setRoles', response.data.roles);
                commit('setEmail', email);
            } catch (error) {
                console.error('Error during login:', error);
                throw error;
            }
        },
        logout({ commit }) {
            commit('clearAuthData');
        },
        loginWithStoredCredentials({ commit }, router) {
            return new Promise((resolve, reject) => {
                const uuid = this.state.uuid;
                const token = this.state.token;
                const expiration = this.state.expiration;
                const email = this.state.email;

                if (token && expiration && new Date(expiration) > new Date()) {
                    commit('setUuid', uuid);
                    commit('setToken', token);
                    commit('setExpiration', expiration);
                    commit('setEmail', email);
                    resolve();

                    router.push({ name: 'MyWorkoutPlans' });
                } else {
                    commit('clearAuthData');
                    reject(new Error('Invalid or expired credentials'));
                }
            });
        },
    },
    getters: {
        isAuthenticated: (state) => !!state.token,
        uuid: (state) => state.uuid,
        token: (state) => state.token,
        expiration: (state) => state.expiration,
        roles: (state) => state.roles,
        email: (state) => state.email,
    },
});