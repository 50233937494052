<template>
  <div>
    <h1>Admin View</h1>
    <p>Welcome, {{ email }}</p>
    <!-- User-specific content -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['email']),
  }
};
</script>

<style scoped>
/* Add your scoped styles here */
</style>