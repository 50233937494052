<template>
  <vue-cal ref="calendar"
           :events="events"
           :config="config"
           :time="false"
           :disable-views="['years', 'year']"
           active-view="month"
           events-on-month-view="long"
           class="vuecal--blue-theme"
           :on-event-click="handleEventClick"/>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';

export default {
  components: { VueCal },
  name: "WorkoutPlansAgenda",
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      config: {
        mode: 'month',
        locale: 'de',
      }
    };
  },
  methods: {
    handleEventClick(event) {
      console.log('test');
      this.$router.push(`/workout/${event.workoutId}`);
    },
  },
}
</script>

<style>
.sport-event {
  background-color: #0d6efd;
  border: 1px solid #0a58ca;
  color: white;
}

.vuecal--blue-theme .vuecal__menu, .vuecal--blue-theme .vuecal__cell-events-count {
  background-color: #0d6efd;
  color: #fff;
}

.vuecal--blue-theme .vuecal__title-bar {
  background-color: #0d6efd;
  opacity: 0.65;
  color: white;
}

.vuecal--blue-theme .vuecal__cell--today, .vuecal--blue-theme .vuecal__cell--current {
  background-color: #0d6efd;
  opacity: 0.4;
}
</style>