<template>
  <div class="w-100">
    <super-user-header class="header"></super-user-header>
    <responsive-form-wrapper @submit="submitForm">
        <h2>Workout toevoegen</h2>
        <div class="mb-3">
          <label for="name" class="form-label">Naam:</label>
          <input type="text" id="name" class="form-control" v-model="workout.name" placeholder="Push day #1" minlength="2" maxlength="60" required>
        </div>
        <div class="mb-3">
          <label for="description" class="form-label">Omschrijving:</label>
          <textarea id="description" class="form-control" v-model="workout.description" rows="3" minlength="2" maxlength="200" placeholder="Eerste push day van de week." required></textarea>
        </div>
        <button type="submit" :disabled="onSubmitting" class="btn btn-primary w-100">Toevoegen</button>
      </responsive-form-wrapper>
  </div>
</template>

<script>
import SuperUserHeader from "@/components/headers/SuperUserHeader";
import ResponsiveFormWrapper from '@/components/ResponsiveFormWrapper.vue';
import { addWorkout } from '@/services/api';

export default {
  components: { SuperUserHeader, ResponsiveFormWrapper },
  data() {
    return {
      onSubmitting: false,
      workout: {
        name: '',
        description: '',
      }
    };
  },
  methods: {
    submitForm() {
      if (!this.onSubmitting) {
        this.onSubmitting = true;
        addWorkout(this.workout)
            .then(response => {
              console.log('Workout toegevoegd:', response.data);
              this.$router.push('/workouts');
            })
            .catch(error => {
              console.error('Fout bij toevoegen oefening:', error);
            })
        .finally(() => {
          this.onSubmitting = false;
        })
      }
    },
  }
};
</script>