<template>
  <div class="w-100">
    <super-user-header class="header"></super-user-header>
    <responsive-form-wrapper @submit="submitForm">
        <h2>Oefening toevoegen</h2>
        <div class="mb-3">
          <label for="name" class="form-label">Naam:</label>
          <input type="text" id="name" class="form-control" v-model="exercise.name" placeholder="Bench Press" required>
        </div>
        <div class="mb-3">
          <label for="description" class="form-label">Omschrijving:</label>
          <textarea id="description" class="form-control" v-model="exercise.description" rows="3" placeholder="A bodybuilding and weightlifting exercise in which a lifter lies on a bench with the feet on the floor and raises a weight with both arms." required></textarea>
        </div>
        <div class="mb-3">
          <label for="type" class="form-label">Type:</label>
          <select id="type" class="form-select" v-model="exercise.type" required>
            <option value="" disabled selected>-- Selecteer type oefening --</option>
            <option value="Strength">Kracht</option>
            <option value="Endurance">Uithoudingsvermogen</option>
            <option value="Flexibility">Flexibiliteit</option>
            <option value="Balance">Balans</option>
          </select>
        </div>
        <div class="mb-3">
          <label for="bodypart" class="form-label">Lichaamsdeel:</label>
          <select id="bodypart" class="form-select" v-model="exercise.bodypart" required>
            <option value="" disabled selected>-- Selecteer welk lichaamsdeel --</option>
            <option value="Chest">Borst</option>
            <option value="Arms">Armen</option>
            <option value="Legs">Benen</option>
            <option value="Core">Core</option>
            <option value="Back">Rug</option>
            <option value="Shoulders">Schouder</option>
            <option value="Biceps">Biceps</option>
            <option value="Triceps">Triceps</option>
            <option value="Frontarms">Voorarmen</option>
          </select>
        </div>
        <button type="submit" :disabled="onSubmitting" class="btn btn-primary w-100">Toevoegen</button>
    </responsive-form-wrapper>
  </div>
</template>

<script>
import SuperUserHeader from "@/components/headers/SuperUserHeader";
import { addExercise } from '@/services/api';
import ResponsiveFormWrapper from '@/components/ResponsiveFormWrapper.vue';
export default {
  components: { SuperUserHeader, ResponsiveFormWrapper },
  data() {
    return {
      onSubmitting: false,
      exercise: {
        name: '',
        description: '',
        type: '',
        bodypart: ''
      }
    };
  },
  methods: {
    submitForm() {
      if (!this.onSubmitting) {
        this.onSubmitting = true;
        addExercise(this.exercise)
            .then(response => {
              console.log('Oefening toegevoegd:', response.data);
              this.$router.push('/exercises');
            })
            .catch(error => {
              console.error('Fout bij toevoegen oefening:', error);
            })
        .finally(() => {
          this.onSubmitting = false;
        });
      }
    },
  }
};
</script>