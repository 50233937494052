<template>
  <div :class="wrapperClass">
    <form @submit.prevent="handleSubmit" class="p-4 border rounded">
      <slot></slot>
    </form>
  </div>
</template>

<script>
export default {
  name: "ResponsiveWrapper",
  data() {
    return {
      wrapperClass: window.innerWidth < 992 ? 'p-0' : 'mx-auto m-3 w-50'
    };
  },
  mounted() {
    window.addEventListener('resize', this.updateWrapperClass);
  },
  methods: {
    updateWrapperClass() {
      this.wrapperClass = window.innerWidth < 992 ? 'p-0' : 'mx-auto m-3 w-50';
    },
    handleSubmit() {
      this.$emit('submit');
    }
  }
};
</script>

<style scoped>

</style>