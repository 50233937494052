<template>
  <div :class="wrapperClass">
    <div class="p-4 border rounded">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResponsivePageWrapper",
  data() {
    return {
      wrapperClass: window.innerWidth < 992 ? 'p-0' : 'mx-auto m-3 w-75'
    };
  },
  mounted() {
    window.addEventListener('resize', this.updateWrapperClass);
  },
  methods: {
    updateWrapperClass() {
      this.wrapperClass = window.innerWidth < 992 ? 'p-0' : 'mx-auto m-3 w-75';
    }
  }
};
</script>