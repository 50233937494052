<template>
    <super-user-header class="header"></super-user-header>
    <div class="w-100">
      <responsive-page-wrapper>
      <h2>Workouts</h2>

      <div class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th>Naam</th>
            <th>Omschrijving</th>
            <th>Acties</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="workout in workouts" :key="workout.id">
            <td>{{ workout.name }}</td>
            <td>{{ workout.description }}</td>
            <td>
              <router-link :to="{ name: 'WorkoutDetails', params: { id: workout.id } }" class="btn btn-primary">
                Bekijken
              </router-link>
              <router-link :to="{ name: 'EditWorkout', params: { id: workout.id } }" class="btn btn-primary">
                Bewerken
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <button @click="navigateToAddWorkout" class="btn btn-primary">Workout toevoegen</button>
  </responsive-page-wrapper>
      </div>
</template>

<script>
import SuperUserHeader from "@/components/headers/SuperUserHeader";
import { getAllWorkouts } from '@/services/api';
import ResponsivePageWrapper from '@/components/ResponsivePageWrapper';

export default {
  components: { SuperUserHeader, ResponsivePageWrapper },
  data() {
    return {
      workouts: []
    };
  },
  mounted() {
    this.fetchWorkouts();
  },
  methods: {
    navigateToAddWorkout() {
      this.$router.push('/add-workout');
    },
    async fetchWorkouts() {
      try {
        const response = await getAllWorkouts();
        this.workouts = response.data;
      } catch (error) {
        console.error('Error fetching workouts:', error);
      }
    }
  }
};
</script>
