<template>
  <div class="w-100">
    <super-user-header class="header"></super-user-header>

    <div class="container">
      <div class="row">
        <div class="col-md-4 mx-auto m-3">
          <form @submit.prevent="addWorkoutSetToWorkout" class="p-4 border rounded">
            <h2>Toevoegen aan workout</h2>

            <div class="form-group mb-3">
              <label for="exerciseSelect">Kies een oefening:</label>
              <select class="form-control" id="exerciseSelect" v-model="workoutSet.exercise" required>
                <option value="" disabled>-- Selecteer --</option>
                <option v-for="exercise in exercises" :key="exercise.id" :value="exercise">
                  {{ exercise.name }}
                </option>
              </select>
            </div>

            <div class="mb-3">
              <label for="sets" class="form-label">Aantal sets:</label>
              <input type="number" id="sets" class="form-control" min="1" max="100" v-model="workoutSet.sets" required>
            </div>

            <div class="mb-3">
              <label for="reps" class="form-label">Aantal reps:</label>
              <input type="number" id="reps" class="form-control" min="1" max="100" v-model="workoutSet.reps" required>
            </div>

            <button type="submit" class="btn btn-primary w-100">Toevoegen aan deze workout</button>
          </form>
        </div>

        <div class="col-md-8 mx-auto m-3">
          <form @submit.prevent="handleEditWorkout" class="p-4 border rounded">
            <h2>{{workout.name}} bewerken</h2>

            <div class="mb-3">
              <label for="name" class="form-label">Naam:</label>
              <input type="text" id="name" class="form-control" v-model="workout.name" placeholder="Push day #1" minlength="2" maxlength="60" required>
            </div>
            <div class="mb-3">
              <label for="description" class="form-label">Omschrijving:</label>
              <textarea id="description" class="form-control" v-model="workout.description" rows="3" minlength="2" maxlength="200" placeholder="Eerste push day van de week." required></textarea>
            </div>

            <table class="table table-striped table-hover table-bordered">
              <thead class="thead-dark">
              <tr>
                <th>Oefening</th>
                <th>Sets</th>
                <th>Reps</th>
                <th>Acties</th>
                <th>Volgorde</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(workoutSet, index) in workoutSets" :key="workoutSet.id">
                <td>{{ workoutSet.exercise.name }}</td>
                <td><input type="number" v-model.number="workoutSet.sets" class="form-control" /></td>
                <td><input type="number" v-model.number="workoutSet.reps" class="form-control" /></td>
                <td>
                  <button @click="removeWorkoutSet(index)" class="btn btn-danger">
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
                <td class="text-center">
                  <button @click.prevent="moveUp(index)" :disabled="index === 0" class="btn btn-primary m-1">
                    <i class="fas fa-arrow-up"></i>
                  </button>
                  <button @click.prevent="moveDown(index)" :disabled="index === workoutSets.length - 1" class="btn btn-primary m-1">
                    <i class="fas fa-arrow-down"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>

            <button type="submit" class="btn btn-primary w-100">Workout opslaan</button>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SuperUserHeader from "@/components/headers/SuperUserHeader";
import {editWorkout, getAllExercises, getWorkout} from "@/services/api";

export default {
  components: { SuperUserHeader },
  data() {
    return {
      isModalVisible: false,
      workoutId: null,
      workoutSets: [],
      workout: {
        id: null,
        name: '',
        description: '',
      },
      exercises: null,
      workoutSet: {
        id: 0,
        exercise: null,
        sets: 0,
        reps: 0,
      }
    };
  },
  mounted() {
    const workoutId = this.$route.params.id;

    this.fetchWorkoutData(workoutId);
    this.fetchAllExercises();
  },
  methods: {
    handleEditWorkout() {
      try {
        editWorkout(this.workout.id, this.workout, this.workoutSets).then(() => {
          this.$router.push(`/workout/${this.workout.id}`);
        });
      } catch (error) {
        alert('Er is een fout opgetreden bij het bijwerken van de workout');
      }
    },
    fetchWorkoutData(workoutId) {
      getWorkout(workoutId)
          .then(response => {
            this.workout = response.data;
            this.workoutSets = this.workout.workoutSets;
            delete this.workout.workoutSets;
          })
          .catch(error => {
            console.error('Fout bij ophalen workoutgegevens:', error);
          });
    },
    fetchAllExercises() {
      getAllExercises()
          .then(response => {
            this.exercises = response.data;
          })
          .catch(error => {
            console.error('Fout bij ophalen oefeningen:', error);
          });
    },
    addWorkoutSetToWorkout() {
      if (!Array.isArray(this.workoutSets)) {
        this.workoutSets = [];
      }
      this.workoutSets.push(this.workoutSet);

      this.workoutSet = {
        id: 0,
        exercise: null,
        sets: 0,
        reps: 0,
      };
    },
    removeWorkoutSet(index) {
      this.workoutSets.splice(index, 1);
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.workoutSets[index - 1];
        this.workoutSets.splice(index - 1, 1, this.workoutSets[index]);
        this.workoutSets.splice(index, 1, temp);
      }
    },
    moveDown(index) {
      if (index < this.workoutSets.length - 1) {
        const temp = this.workoutSets[index + 1];
        this.workoutSets.splice(index + 1, 1, this.workoutSets[index]);
        this.workoutSets.splice(index, 1, temp);
      }
    }
  },
};
</script>