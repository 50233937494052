<template>
  <AdminHeader v-if="hasRole('Admin')" />
  <SuperUserHeader v-else-if="hasRole('SuperUser')" />
  <UserHeader v-else />

  <div class="w-100">
    <responsive-page-wrapper>
      <h2>Mijn planning</h2>
      <div class="mb-4">
        <WorkoutPlansAgenda :events="events"></WorkoutPlansAgenda>
      </div>
      </responsive-page-wrapper>
  </div>
</template>

<script>
import WorkoutPlansAgenda from "@/components/WorkoutPlansAgenda"
import SuperUserHeader from "@/components/headers/SuperUserHeader";
import { getWorkoutPlansFromUser } from "@/services/api";
import 'vue-cal/dist/vuecal.css';
import AdminHeader from "@/components/headers/AdminHeader";
import UserHeader from "@/components/headers/UserHeader";
import {mapGetters} from "vuex";
import ResponsivePageWrapper from '@/components/ResponsivePageWrapper';

export default {
  components: { SuperUserHeader, AdminHeader, UserHeader, WorkoutPlansAgenda, ResponsivePageWrapper },
  computed: {
    ...mapGetters(['roles']),
  },
  name: "MyWorkoutPlansView",
  data() {
    return {
      events: [],
    };
  },
  created() {
    this.fetchWorkoutPlansFromUser();
  },
  methods: {
    async fetchWorkoutPlansFromUser() {
      try {
        const response = await getWorkoutPlansFromUser();

        this.events = response.data.map(event => ({
          start: event.date.split('T')[0],
          end: event.date.split('T')[0],
          title: event.workout.name,
          content: `<a href="/workout/${event.workout.id}" class="event-link"><i class="fas fa-running" style="color: #FFF;"></i></a>`,
          class: 'sport-event',
          workoutId: event.workout.id,
        }));
      } catch (error) {
        console.error('Error fetching workouts:', error);
      }
    },
    hasRole(role) {
      return this.roles.includes(role);
    },
  },
}
</script>