<template>
  <SuperUserHeader/>
  <div class="w-100">
    <responsive-page-wrapper>
      <div class="mb-4">
        <WorkoutPlansAgenda :events="events"></WorkoutPlansAgenda>
      </div>
      <hr>
      <router-link :to="{ name: 'AddWorkoutPlan', params: { id: this.userId } }" class="btn btn-primary w-100">Workout plan toevoegen</router-link>
    </responsive-page-wrapper>
  </div>
</template>

<script>
import WorkoutPlansAgenda from "@/components/WorkoutPlansAgenda"
import SuperUserHeader from "@/components/headers/SuperUserHeader";
import { getWorkoutPlansFromUser } from "@/services/api";
import 'vue-cal/dist/vuecal.css';
import ResponsivePageWrapper from '@/components/ResponsivePageWrapper';

export default {
  components: { SuperUserHeader, WorkoutPlansAgenda, ResponsivePageWrapper },
  props: ['id'],
  name: "EditWorkoutPlansView",
  data() {
    return {
      userId: null,
      events: [],
    };
  },
  created() {
    this.userId = this.$route.params.id;
    this.fetchWorkoutPlansFromUser();
  },
  methods: {
    async fetchWorkoutPlansFromUser() {
      try {
        const response = await getWorkoutPlansFromUser(this.userId);

        this.events = response.data.map(event => ({
          start: event.date.split('T')[0],
          end: event.date.split('T')[0],
          title: event.workout.name,
          content: `<i class="fas fa-running"></i>`,
          class: 'sport-event',
          workoutId: event.workout.id,
        }));
      } catch (error) {
        console.error('Error fetching workouts:', error);
      }
    },
  },
}
</script>

